import React, { useState, useEffect } from 'react';
import Prismic from '@prismicio/client';
import BodyClassName from 'react-body-classname';
import renderHtml from 'htmr';

import { formatTitle } from '../utils/shop';
import gifLoading from '../images/gif/loading.gif';
import ReadItem from '../components/Read/ReadItem';
import iconSearch from '../images/icon-search-2.svg';
import WatchItem from '../components/Watch/WatchItem';
import Client from 'shopify-buy/index.unoptimized.umd';
import ShopProduct from '../components/Shop/ShopProduct';
import PodCastCard from '../components/podcast/podcast-card';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import PodCastListsWithCategories from '../components/podcast/podCastListsWithCategories';

const shopifyClient = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_FRONT_ACCESS_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
});

const SearchPage = (props) => {
  const [keyWord, setKeyWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [data, setData] = useState([]);

  const [podCastData, setPodCastData] = useState([]);
  const [filter, setFilter] = useState('all');
  const [productLists, setProductLists] = useState([]);
  const apiEndpoint = 'https://lineage.prismic.io/api/v2';
  const client = Prismic.client(apiEndpoint);

  const queryFromPrismic = async (keyword, fields, orderings, saveData) => {
    await client
      .query([Prismic.Predicates.any('document.type', fields), Prismic.Predicates.fulltext('document', `${keyword}`)], {
        pageSize: 100,
        ...orderings,
      })
      .then((response) => {
        if (response.results.length > 0) {
          saveData(response.results);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          saveData([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const fetchSearchResult = async (keyword) => {
    if (keyword.length < 3) return;

    setIsLoading(true);
    const productsQuery = shopifyClient.graphQLClient.query((root) => {
      root.addConnection('products', { args: { first: 10, query: `title:*${keyword}*` } }, (product) => {
        product.add('title');
        product.add('priceRange', (princeRange) => {
          princeRange.add('minVariantPrice', (minVariantPrice) => {
            minVariantPrice.add('amount');
          });
        });
        product.addConnection('images', { args: { first: 1 } }, (image) => {
          image.add('src');
        });
      });
    });

    shopifyClient.graphQLClient.send(productsQuery).then(({ model, data }) => {
      setProductLists(data.products.edges);
    });

    queryFromPrismic(
      keyword,
      ['read', 'episodes'],
      {
        orderings: '[my.read.title, my.read.priority]',
        orderings: '[my.episodes.title, my.episodes.priority]',
      },
      setData
    );

    queryFromPrismic(
      keyword,
      ['podcast'],
      {
        orderings: '[my.podcast.title, my.podcast.priority]',
      },
      setPodCastData
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchSearchResult(keyWord);
    }
  };

  const getParams = (url) => {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      if (vars[i]) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
    return params;
  };

  const mapWatchToEpisode = ({ uid, data }) => {
    return {
      uid,
      data,
    };
  };

  const mapRead = (read) => {
    return {
      url: `/read/${read.uid}`,
      title: read.data.title[0],
      readDuration: read.data.read_duration1[0],
      featuredImage: read.data.featured_image,
    };
  };

  const mapProduct = (product) => {
    return {
      productId: product?.id,
      url: formatTitle(product?.title),
      featuredImage: {
        gatsbyImageData: {
          images: { fallback: { src: product?.images?.edges?.length ? product?.images?.edges[0].node?.src : '' } },
        },
      },
      title: product?.title,
      priceRange: product?.priceRange,
    };
  };

  const mapPodCast = (podcast) => {
    return {
      data: {
        podCastTitle: podcast.data.podcast_title,
        thumnailImage: podcast.data.thumbnail_image,
        podcastDuration: podcast.data.podcast_duration,
      },
      uid: `/podcast/${podcast.uid}`,
    };
  };
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      const params = getParams(window.location.search);

      if (params['keyword'] !== keyWord) {
        setIsFirstLoad(true);
        setKeyWord(params['keyword']);
        fetchSearchResult(params['keyword']);
      }
    }
  }, [props]);

  return (
    <BodyClassName className="body-dark page-search">
      <DefaultLayoutComponent title="Search">
        <div className="site-main">
          <div className="search-box">
            <div className="wrapper wrapper-lg pd-x-sm d-flex align-items-center h-100">
              <input
                type="text"
                className="search-keyword w-100"
                value={keyWord}
                onChange={(e) => setKeyWord(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <div className="search-icon-wrapper" onClick={() => fetchSearchResult(keyWord)}>
                <img src={iconSearch} alt="Search" />
              </div>
            </div>
          </div>
          <div className="search-results">
            {isLoading && (
              <div className="loading-wrapper d-flex justify-content-center">
                <img src={gifLoading} alt="Loading Gif" />
              </div>
            )}
            {data && !isLoading && (
              <div className="categories">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <span className={`item ${filter === 'all' ? 'selected' : ''}`} onClick={() => setFilter('all')}>
                    All
                  </span>
                  {data && data.filter((e) => e.type === 'episodes').length > 0 && (
                    <span className={`item ${filter === 'watch' ? 'selected' : ''}`} onClick={() => setFilter('watch')}>
                      Watch
                    </span>
                  )}
                  {data && data.filter((r) => r.type === 'read').length > 0 && (
                    <span className={`item ${filter === 'read' ? 'selected' : ''}`} onClick={() => setFilter('read')}>
                      Read
                    </span>
                  )}
                  {podCastData && podCastData.length > 0 && (
                    <span
                      className={`item ${filter === 'listen' ? 'selected' : ''}`}
                      onClick={() => setFilter('listen')}
                    >
                      Listen
                    </span>
                  )}
                  {productLists && productLists.length > 0 && (
                    <span className={`item ${filter === 'shop' ? 'selected' : ''}`} onClick={() => setFilter('shop')}>
                      Shop
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="wrapper wrapper-lg">
              <div className="cat_watch-list">
                {(filter === 'all' || filter === 'watch') &&
                  !isLoading &&
                  data &&
                  data.filter((e) => e.type === 'episodes').length > 0 && (
                    <>
                      <div className="cat_watch-head cat-title pd-x-sm">Watch</div>
                      <div className="d-flex columns-4">
                        {data
                          .filter((e) => e.type === 'episodes' && e.data.language === 'English')
                          .map((ep) => (
                            <WatchItem
                              className="cols"
                              key={ep.uri}
                              data={mapWatchToEpisode(ep)}
                              type="landscape"
                              showPlayIcon
                            />
                          ))}
                      </div>
                    </>
                  )}
              </div>
              <div className="cat_watch-list">
                {(filter === 'all' || filter === 'read') &&
                  !isLoading &&
                  data &&
                  data.filter((r) => r.type === 'read').length > 0 && (
                    <>
                      <div className="cat_watch-head cat-title pd-x-sm">Read</div>
                      <div className="d-flex columns-4">
                        {data
                          .filter((r) => r.type === 'read')
                          .map((r) => (
                            <ReadItem className="cols" key={r.uid} item="carousel" read={mapRead(r)} />
                          ))}
                      </div>
                    </>
                  )}
              </div>
              <div className="podcast">
                <div className="cat_watch-list">
                  {(filter === 'all' || filter === 'listen') && !isLoading && podCastData && podCastData.length > 0 && (
                    <>
                      <div className="cat_watch-head cat-title pd-x-sm">Listen</div>
                      <PodCastListsWithCategories
                        data={podCastData.map((item) => mapPodCast(item))}
                        showCategory={true}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="cat_watch-list">
                {(filter === 'all' || filter === 'shop') && !isLoading && productLists && productLists.length > 0 && (
                  <>
                    <div className="cat_watch-head cat-title pd-x-sm">Shop</div>
                    <div className="product-list columns d-flex">
                      {productLists?.map((p) => (
                        <ShopProduct className="cols cols-4 list-item" key={p.id} product={mapProduct(p.node)} />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default SearchPage;
